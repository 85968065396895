/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	
	function moveGoogleMap() {
		jQuery('footer#site-footer .google-map').insertBefore('footer#site-footer .copyright');
	}

	function checkForViewportChange () {
		var state = window.getComputedStyle(document.body,':before').content;
		this.lastState = this.lastState || "";
		if (state !== this.lastState) {
		if ((state === '\"mobile\"') || (state === 'mobile')) {
			// Footer
			// >> move copyright to sit below Google Map
			jQuery('footer#site-footer .google-map').insertBefore('footer#site-footer .copyright');
		} else if ((state === '\"tablet\"') || (state === 'tablet')) {
			// Homepage
			// >> move hero header to sit above content
			jQuery('body.home .hero__content header').insertBefore('body.home .hero__content .row').addClass('text-center');
			// >> move hero footer to sit below content
			jQuery('body.home .hero__content footer').insertAfter('body.home .hero__content .row').addClass('text-center');
			// >> feature rows: move header to sit above content
			jQuery('body.home .feature1 header').insertBefore('body.home .feature1 .row').addClass('text-center');
			jQuery('body.home .feature2 header').insertBefore('body.home .feature2 .row').addClass('text-center');

		} else if ((state === '\"desktop\"') || (state === 'desktop')) {
			// Footer
			// >> change Google Map size
			jQuery('footer#site-footer .google-map').removeClass('embed-responsive-4by3').addClass('embed-responsive-16by9');
		}
		this.lastState = state;
	}}
	window.setInterval (checkForViewportChange, 150);
	
  // Fire Fancybox
  jQuery(".fancybox").fancybox();

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
